var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"router-view-container"},[_c('div',{staticClass:"flex items-center space-x-4 mb-5"},[_c('vs-button',{staticClass:"flex-shrink-0",attrs:{"border":"","size":"large"},on:{"click":function($event){return _vm.$router.go(-1)}}},[_c('svg-icon',{attrs:{"icon-class":"arrow_left","className":"primary"}})],1),_c('h1',{staticClass:"page_title"},[_vm._v(_vm._s(_vm.$route.meta.title))])],1),_c('main',{staticClass:"space-y-5"},[_c('section',{staticClass:"grid grid-cols-1 md:grid-cols-1 gap-6"},[_c('ValidationObserver',{staticClass:"box flex flex-col",attrs:{"tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('div',{staticClass:"mb-1 w-24"},[_c('h1',{staticClass:"title"},[_vm._v("圖(104*156) "),_c('span',[_vm._v("*")])]),_c('div',{staticClass:"upload aspect-ratio-1-1_global_style"},[(_vm.img)?_c('img',{staticClass:"uploadImg",attrs:{"src":_vm.img}}):_c('div',{staticClass:"upload_icon"},[_c('svg-icon',{staticStyle:{"width":"3rem","height":"3rem"},attrs:{"icon-class":"upload","className":"gray-divide"}}),_c('p',{staticClass:"text-gray-400 text-xs"},[_vm._v("點擊上傳或拖曳圖片")])],1),_c('vs-input',{staticClass:"img_input",attrs:{"type":"file","accept":"image/.png,.jpg,.jpeg,.gif,.PNG,.JPG,.JPEG,.GIF"},on:{"change":_vm.uploadImg}})],1),_c('ValidationProvider',{staticClass:"relative",attrs:{"mode":"aggressive","name":"圖","placeholder":"請選擇圖","rules":"required","tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('vs-input',{staticClass:"w-full",staticStyle:{"display":"none"},model:{value:(_vm.img),callback:function ($$v) {_vm.img=$$v},expression:"img"}}),_c('errorDiv',[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('div',[_c('h1',{staticClass:"title"},[_vm._v("標題 "),_c('span',[_vm._v("*")])]),_c('ValidationProvider',{staticClass:"relative",attrs:{"mode":"aggressive","name":"標題","placeholder":"請輸入標題","rules":"required|max:15","tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('vs-input',{staticClass:"w-full",model:{value:(_vm.name),callback:function ($$v) {_vm.name=$$v},expression:"name"}}),_c('errorDiv',[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('div',[_c('h1',{staticClass:"title"},[_vm._v("描述 "),_c('span',[_vm._v("*")])]),_c('ValidationProvider',{staticClass:"relative",attrs:{"mode":"aggressive","name":"描述","placeholder":"請輸入描述","rules":"required|max:50","tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.descs),expression:"descs"}],staticClass:"w-full",attrs:{"rows":"3"},domProps:{"value":(_vm.descs)},on:{"input":function($event){if($event.target.composing){ return; }_vm.descs=$event.target.value}}}),_vm._v(" "),_c('errorDiv',[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('div',{staticClass:"flex justify-between"},[(_vm.id != 0)?_c('vs-button',{attrs:{"size":"large","color":"danger","border":""},on:{"click":function($event){_vm.delete_dialog = true}}},[_c('span',{staticClass:"font-medium"},[_vm._v("刪除")])]):_vm._e(),_c('div',{staticClass:"w-4"}),_c('vs-button',{ref:"saveBtn",attrs:{"size":"large","disabled":invalid},on:{"click":function($event){return _vm.saveBtn(invalid)}}},[_c('span',{staticClass:"font-medium"},[_vm._v("儲存")])])],1)]}}])})],1)])]),_c('vs-dialog',{attrs:{"overflow-hidden":"","blur":"","not-center":""},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('h1',{staticClass:"text-base font-medium text-gray-text_dark"},[_vm._v("是否要刪除"+_vm._s(_vm.title)+"資料？")])]},proxy:true},{key:"footer",fn:function(){return [_c('div',{staticClass:"flex justify-end space-x-4"},[_c('vs-button',{attrs:{"size":"large","transparent":""},on:{"click":function($event){_vm.delete_dialog = false}}},[_vm._v("取消")]),_c('vs-button',{ref:"deleteBtn",attrs:{"size":"large","transparent":"","color":"danger"},on:{"click":_vm.deleteBtn}},[_vm._v("刪除")])],1)]},proxy:true}]),model:{value:(_vm.delete_dialog),callback:function ($$v) {_vm.delete_dialog=$$v},expression:"delete_dialog"}},[_c('div',{staticClass:"mb-3"},[_c('p',{staticClass:"text-sm text-gray-text_light"},[_vm._v("刪除的"+_vm._s(_vm.title)+"資料無法復原，確定仍要繼續？")])])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }